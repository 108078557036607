import { MetricsPanelCtrl } from 'grafana/app/plugins/sdk';
import defaultsDeep from 'lodash/defaultsDeep';
import _ from 'lodash';

export default class SimpleCtrl extends MetricsPanelCtrl {
  static templateUrl = 'partials/module.html';

  panelDefaults = {
    tagVarName: '',
    valueVarName: '',
    searchVarName: '',
    periodsVarName: '',
    tagDefinitions: [],
    operations: [
      { text: '=', value: '=' },
      { text: '>', value: '>' },
      { text: '<', value: '<' },
      { text: '!=', value: '<>' }
    ],
    valueFieldName: 'FloatValue',
    tagName: 'Type',
    periodTemplate: 'SELECT * FROM telemetry WHERE '
  };
  searchText = '';
  selectedTag = (this.panel.tagDefinitions) ? this.panel.tagDefinitions[0].tag : '';
  selectedOption = (this.panel.operations) ? this.panel.operations[0].value : '';

  var1history = 'true';
  periodsHistory = 'true';
  tagVarHistory = 'true';
  valueVarHistory = 'true';
  timeRange: any = {};

  /** @ngInject */
  constructor($scope, $injector, private variableSrv) {
    //private contextSrv,
    super($scope, $injector);
    defaultsDeep(this.panel, this.panelDefaults);

    // Get results directly as DataFrames
    (this as any).dataFormat = 'series';

    this.events.on('init-edit-mode', this.onInitEditMode.bind(this));
    this.events.on('render', this.onRender.bind(this));
    this.events.on('data-error', this.onDataError.bind(this));
    this.events.on('data-received', this.onDataReceived.bind(this));
    this.events.on('data-snapshot-load', this.onDataSnapshotLoad.bind(this));
  }

  onDataSnapshotLoad(snapshotData: any) {
    this.onDataReceived(snapshotData);
  }

  onDataReceived(dataList: any) {
    //console.log(dataList);
    //no search defined
    if (!this.searchText) {
      var trueResult = "(" + this.panel.periodTemplate + " true)";
      if (this.periodsHistory !== trueResult) {
        this.periodsHistory = trueResult;
        this.updateVarable(this.panel.periodsVarName, trueResult);
      }
      return;
    }

    let periodsText = '';
    //calc periods
    periodsText = this.calcPeriods(dataList);
    //compare var and update if needed
    if (this.periodsHistory !== periodsText) {
      this.periodsHistory = periodsText;
      this.updateVarable(this.panel.periodsVarName, periodsText);
    }
  }

  calcPeriods(dataList: any) {
    if (!dataList || !dataList[0] || dataList[0].columns.length === 0 || dataList[0].rows === 0) {
      return "(" + this.panel.periodTemplate + " false)";
    }

    //find value column
    let colIdx = 0;
    for (let index = 0; index < dataList[0].columns.length; index++) {
      if (dataList[0].columns[index].text === this.panel.valueFieldName)
        colIdx = index;
    }

    let periods: any[] = [];
    let lastPeriod = { start: 0, end: 0 };

    for (let index = 0; index < dataList[0].rows.length; index++) {
      const r = dataList[0].rows[index];

      //check operation
      if (this.calcOperation(r[colIdx].toString(), this.selectedOption, this.searchText)) {
        //start/continue period
        if (lastPeriod.start === 0) {
          lastPeriod.start = r[0] * 1000000;
        }
      } else {
        //stop/ignore period
        if (lastPeriod.start > 0) {
          //end started
          lastPeriod.end = r[0] * 1000000;
          periods.push(lastPeriod);
          lastPeriod = { start: 0, end: 0 };
        }
      }
    }

    //close last period
    if (lastPeriod.start > 0) {
      this.timeRange = this.variableSrv.templateSrv.timeRange;
      lastPeriod.end = this.timeRange.to._d.getTime() * 1000000;
      periods.push(lastPeriod);
    }

    if (periods.length > 0) {
      let expression = '';
      for (let index = 0; index < periods.length; index++) {
        if (index > 0)
          expression += ",";
        const element = periods[index];
        expression += "(" + this.panel.periodTemplate + " (time >= " + element.start + " AND time <= " + element.end + "))";
      }
      return expression;
    }

    return "(" + this.panel.periodTemplate + " false)";
  }

  calcOperation(fieldValue, selOperation, searchValue) {
    switch (selOperation) {
      case '=':
        return fieldValue === searchValue;
      case '>':
        return fieldValue > searchValue;
      case '<':
        return fieldValue < searchValue;
      case '!=':
        return fieldValue !== searchValue;
      default:
        return false;
    }
  }

  onInitEditMode() {
    this.addEditorTab('Options', `public/plugins/${this.pluginId}/partials/options.html`, 2);
  }

  onRender() {
    return;
  }

  onDataError(err: any) {
    console.log('onDataError', err);
  }

  updateVarable(varname: string, value: string) {
    if (!varname) {
      return;
    }
    const v = this.getVariable(varname);
    if (v && v.current.value !== value) {
      this.variableSrv.setOptionAsCurrent(v, {
        text: value,
        value: value,
      });
      this.variableSrv.variableUpdated(v, true);
    }
  }

  getVariable(varname: string): any {
    const v = _.find(this.variableSrv.variables, (check: { name: string }) => {
      return check.name === varname;
    });
    return v;
  }

  onSubmitSearch() {
    let combined = "true";
    let tagTxt = "true";
    let valueTxt = "true";

    if (this.selectedTag)
      tagTxt = this.panel.tagName + " = '" + this.selectedTag + "'";

    if (this.searchText) {
      combined = this.panel.tagName + " = '" + this.selectedTag + "' AND "
        + this.panel.valueFieldName + " " + this.selectedOption + " " + this.searchText;
      valueTxt = this.panel.valueFieldName + " " + this.selectedOption + " " + this.searchText;
    }

    if (this.var1history !== combined) {
      this.var1history = combined;
      this.updateVarable(this.panel.searchVarName, combined);
    }

    if (this.tagVarHistory !== tagTxt) {
      this.tagVarHistory = tagTxt;
      this.updateVarable(this.panel.tagVarName, tagTxt);
    }

    if (this.valueVarHistory !== valueTxt) {
      this.valueVarHistory = valueTxt;
      this.updateVarable(this.panel.valueVarName, valueTxt);
    }
  }

  onAddDefinition() {
    this.panel.tagDefinitions.push({ text: "Text", tag: "Tag" });
  }
  onRemoveDefinition(idx: number) {
    this.panel.tagDefinitions.splice(idx, 1);
  }
  onGeneratePeriods() {
    console.log(this.panel.generatePeriods);
  }
}

export { SimpleCtrl as PanelCtrl };
